/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Futura PT Medium";
  src: local("Futura PT Medium"), url(./assets/fonts/FuturaPTMedium.otf) format("opentype");
}


@font-face {
  font-family: "Futura PT Bold";
  src: local("Futura PT Bold"), url(./assets/fonts/FuturaPTBold.otf) format("opentype");
}

@font-face {
  font-family: "Futura PT Book";
  src: local("Futura PT Book"), url(./assets/fonts/FuturaPTBook.otf) format("opentype");
}

body {
  font-family: "Futura PT Medium";
  font-size: 16px;
}

strong, h1, h2, h3, h4, h5, h6 {
  font-family: "Futura PT Bold";
}

input:focus, textarea:focus, select:focus{
  outline: none !important;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}
